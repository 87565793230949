/**
* Website Name: Reemooz
Design & Developed By: Shrikant Gadkar (Fusion Informatics Pvt.Ltd)
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  font-family: "Inter", sans-serif;
  color: #111513;
}

a {
  color: #0077DC;
  text-decoration: none !important;
}

a:hover {
  color: #0077DC;
  text-decoration: none !important;
}

.fonts-11 {
  font-size: 11px !important;
}

.fonts-12 {
  font-size: 12px !important;
}

.fonts-13 {
  font-size: 13px !important;
}

.fonts-14 {
  font-size: 14px !important;
}

.fonts-20 {
  font-size: 20px;
}


.text-success {
  color: #4DFF0F !important
}

.bg-white {
  background-color: #fff;
}

.bg-f9 {
  background-color: #F9F9F9;
}

.bg-transparent {
  background-color: transparent !important;
}

.opacity-7 {
  opacity: 0.7;
}

.w-space {
  white-space: nowrap;
}

.match-detail table td {
  border-top: 0;
  border-bottom: 0;
  background-color: #F9F9F9;
  vertical-align: top;
}

.match-detail table th {
  border-bottom: 0;
}

tbody::before

/* {
  content: '';
  display: block;
  height: 15px;

} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}

.limegreen {
  color: #DBFF00;
}

.p-12 {
  font-size: 12px !important;
}

.p-13 {
  font-size: 13px !important;
}

.p-18 {
  font-size: 18px !important;
}

.p-22 {
  font-size: 22px !important;
}

.Heading-h1 {
  font-size: 64px;
}

.Heading-h2 {
  font-size: 36px;
}

.Heading-h3 {
  font-size: 26px;
}

.EventTitle {
  font-size: 26px;
}

.h-2 {
  font-size: 2.75rem;
  font-weight: 600;
}

.bg-primary {
  background-color: #0077DC !important;
}

.desktop-right {
  text-align: right;
}

.desktop-pt200 {
  padding-top: 200px;
}

.text-theme {
  color: #0077DC;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 90% !important;
  padding-left: calc(var(--bs-gutter-x)* .5);
  padding-right: calc(var(--bs-gutter-x)* .5);
  margin-left: auto;
  margin-right: auto;
}

.mee-auto {
  margin-right: auto !important;
}

.mss-auto {
  margin-left: auto !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #0077DC;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #0077DC;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.body-top {
  padding-top: 100px;
}

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0px;
  position: fixed;
  width: 100%;
  top: 0;
  background: #FFF !important;
  box-shadow: 0 4px 10px rgba(4, 13, 41, 0.05) !important;

}

#header.header-scrolled,
#header.header-inner-pages {
  background: #FFF !important;
  box-shadow: 0 4px 10px rgba(4, 13, 41, 0.05) !important;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 84px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #0D1603;
  white-space: nowrap;
  transition: 0.3s;
  z-index: 1;
  position: relative;
  text-decoration: none !important;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #0077DC;
}

.navbar a::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 32%;
  transform: translateY(-50%);
  /* background-image: url("../../assets/img/img/menu-ball.svg"); */
  width: 42px;
  height: 42px;
  background-size: contain;
  z-index: -1;
  transition: all 0.3s;
  opacity: 0;
}

.navbar a.active::before {
  opacity: 1;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  border: 2px solid #25380e;
  background-color: #25380e;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #25380e;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 0px;
  /* top: calc(100% + 30px); */
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
  top: 66px !important;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #000;
  text-decoration: none !important;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #0077DC;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  /* top: 0; */
  top: -10px !important;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #000;
  font-size: 44px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
    position: absolute;
    right: 0px
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(37, 56, 14, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #111513;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #0077DC;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: #FFF;
  text-align: center;
  justify-content: center;
  padding: 20px;

}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #0077DC;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
  visibility: visible !important;
}

.login-avtar {
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 100px;
  margin-right: 10px;
}

.login-avtar img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 100px;
}

.avtar32 {
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 100px;
  margin-right: 10px;
}

.avtar32 img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 100px;
}

.notificationDropdown-box {
  min-width: 320px !important;
  max-width: 320px !important;
  right: 0;
  top: 68px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border: none !important;
}

ul.notification-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
}

ul.notification-list li {
  display: block;
}

ul.notification-list a {
  display: block;
  cursor: pointer;
  padding: 15px;
  border-top: 1px solid #D9D9D9;
}

ul.notification-list a:hover {
  color: #000 !important;
}

.count-badge {
  position: absolute;
  top: 0;
  right: 0px;
  width: 20px;
  height: 20px;
  background: #fb5959;
  border-radius: 100px;
  font-size: 11px;
  color: #FFF;
  line-height: 20px;
  text-align: center;
}

/*==========================================
Button Styling
============================================*/
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary {
  background-color: #0077DC;
  color: #FFF;
  border-radius: 6px;
  padding: 12px 30px;
  border-color: #0077DC;
  font-weight: 400;
  cursor: pointer;
}

.btn-primary:hover,
.btn-primary.active {
  background-color: #0077DC;
  color: #FFF;
  border-color: #0077DC;
}

.btn-primarysm {
  background-color: #0077DC !important;
  color: #FFF !important;
  border-radius: 6px !important;
  padding: 0.25rem 0.5rem !important;
  border-color: #0077DC !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}

.btn-primarysm:hover,
.btn-primarysm.active {
  background-color: #0077DC !important;
  color: #FFF !important;
  border-color: #0077DC !important;
}


.btn-white {
  background-color: #FFF !important;
  color: #000 !important;
  border-radius: 50px !important;
  padding: 15px 30px !important;
  border-color: #FFF !important;
  font-weight: 400 !important;
}

.btn-white:hover,
.btn-white.active {
  background-color: #FFF !important;
  color: #000 !important;
  border-color: #FFF !important;
}

.btn-outline-white {
  background-color: transparent;
  border-radius: 6px;
  color: #0077DC;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.action-btn {
  background: #D9D9D9;
  padding: 5px;
  border-radius: 4px;
  color: #6e6e6e;
  margin-left: 5px;
}

.action-btn:hover {
  background: #0077DC;
  color: #FFF;
}

.btn-small {
  background-color: #0077DC !important;
  color: #FFF !important;
  border-radius: 100px !important;
  padding: 5px 15px !important;
  border-color: #0077DC !important;
  font-weight: 400 !important;
}

.btn-small:hover,
.btn-small:focus,
.btn-small.active {
  background-color: #0077DC !important;
  color: #FFF !important;
  border-radius: 100px;
  padding: 5px 15px;
  border-color: #0077DC !important;
  font-weight: 400;
}

.upload-btn-wrapper {
  position: relative;
  display: inline-block;
}

.btn-upload-file {
  color: #0077DC;
  border: 1px solid transparent;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: .75rem .75rem;
  border-radius: 6px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  display: block;
  width: 100%;
}

.explore-btn {
  border-radius: 100px;
  padding: 5px 30px;
  font-weight: 400;
  border: none;
  width: 100% !important;
  display: block;
  text-align: center;
  color: #FFF;
}

.explore-btn:hover {
  color: #FFF;
}

.bg-blue {
  background-color: #0077DC;
  color: #FFF;
}

.text-blue {
  color: #0077DC;
}

.bg-green {
  background-color: #5E9743;
  color: #FFF;
}

.text-green {
  color: #5E9743;
}

.bg-teal {
  background-color: #289F98;
  color: #FFF;
}

.text-teal {
  color: #289F98;
}

.bg-brown {
  background-color: #9F3628;
  color: #FFF;
}

.text-brown {
  color: #9F3628;
}

.bg-light-purple {
  background-color: #93678E;
  color: #FFF;
}

.text-light-purple {
  color: #93678E;
}

.bg-dark-gray {
  background-color: #686868;
  color: #FFF;
}

.bg-dark {
  background-color: #252525;
  color: #FFF;
}

.text-dark-gray {
  color: #686868;
}

.bg-gray {
  background-color: #A7A7A7;
  color: #FFF;
}

.bg-grays {
  background-color: #E5E4E2;
  border-radius: 22px;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #B7B7B7;
  --bs-btn-border-color: #B7B7B7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #B7B7B7;
  --bs-btn-disabled-border-color: #B7B7B7;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2DAE00;
  --bs-btn-border-color: #2DAE00;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2DAE00;
  --bs-btn-hover-border-color: #2DAE00;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2DAE00;
  --bs-btn-active-border-color: #2DAE00;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2DAE00;
  --bs-btn-disabled-border-color: #2DAE00;
  border-radius: 6px;
  padding: 12px 30px;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.bosTeam-wrapper {
  position: absolute;
  bottom: 100px;
}

.body-welcome-page {
  background-image: url("../img/welcome-page-banner.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.welcome-page {
  background-image: url("../../assets/img/bg 1.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dashboard-section {
  background-image: url("../img/dasboard-background.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.casestudy-section {
  background-image: url("../img/case-studies-bg.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.adduser-section {
  background-image: url("../img/businesspeople-having-discussion-office.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.user-profile-section {
  background-image: url("../img/user-profile-page-bg.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

/*-------------------------------------------------------
Form Control CSS
---------------------------------------------------------*/
.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  margin-bottom: 5px;
}

.form-control {
  padding: .75rem .75rem !important;
}

.form-select {
  padding: .75rem .75rem !important;
}

.form-group .input-icon {
  position: absolute;
  left: 15px;
  top: 15px;
}

.control-search {
  border-radius: 32px !important;
  background-color: #D9D9D9 !important;
  padding-left: 40px !important;
}

.profile-avtar {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: auto;
  margin-bottom: 15px;
  position: relative;
}

.profile-avtar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.input--file {
  right: 0;
  position: absolute;
  color: #1b1b1b;
  bottom: 10px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #ebebeb;
  border-radius: 50%;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;

}

.input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 32px;
  cursor: pointer;
}

/*-------------------------------------------------------
Login Page CSS
-------------------------------------------------------*/
.body-login {
  background-image: url("../../assets/img/businesspeople-having-discussion-office.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-modal {
  display: block;
  border-radius: 30px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.login-modal .login-body {
  padding: 30px;
}

.btn-login {
  font-weight: 700;
  font-size: 18px;
  background-color: transparent;
  color: #000;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: transparent;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
  background: #424242;
  color: #FFF;
  padding: 60px 0;
}

ul.footernav {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.footernav li {
  display: block;
}

ul.footernav a {
  display: block;
  padding: 5px 0;
  color: #FFFF;
}

ul.social-media {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.social-media li {
  display: inline-block;
}

ul.social-media li a {
  display: block;
  padding: 5px;
  color: #FFF;
  font-size: 1.2rem;
}

.border-right-end {
  border-right: 1px solid #dee2e6 !important;
}

/*--------------------------------------------------------------
# Listing CSS
--------------------------------------------------------------*/
ul.LinkList-nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.LinkList-nav li {
  display: block;
}

ul.LinkList-nav li a {
  display: block;
  color: #0077DC;
  text-decoration: underline;
  padding: 15px 0px;
}

ul.LinkList-nav li a img {
  margin-right: 10px;
}

ul.project-document {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.project-document li {
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #D9D9D9;
}

ul.project-document li:last-child {
  border-bottom: none;
}

/*------------------------------------------
Table CSS
-------------------------------------------*/
.table {
  padding: 0.8rem 0.4rem !important;
}

.table>thead th {
  font-size: 12px;
  font-weight: 600;
  vertical-align: middle;
}

.table>tbody td {
  font-size: 12px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: #F9F9F9 !important;
}

.table>:not(caption)>*>* {
  padding: .85rem .5rem !important;
}

.project-close>.table>tbody .bg-d3 td {
  background-color: #d3d3d3;
}

.page-link {
  color: #0077DC;
}

.active>.page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #0077DC;
  border-color: #FFF;
}

/*-----------------------------------------
Page Header
------------------------------------------*/
.pageheader {
  background-color: #0077DC;
  color: #FFF;
  padding: 15px 0;
}

.pageheader p {
  margin-bottom: 0px;
  /* margin-left: -100px !important; */
}

.page-title {
  background: #0077dc;
  color: #FFF;
  padding: 15px 100px;
  border-radius: 100px;
  font-weight: 600;
}

.page-title-gray {
  background: #686868;
  color: #FFF;
  padding: 15px 100px;
  border-radius: 100px;
  font-weight: 600;
}

/*------------------------------------------
Card Styling
------------------------------------------*/
.card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.statistic-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 11px 1px;
  background-color: #FFF;
}

.statistic-card .card-body {
  flex: 1 1 auto;
  padding: 2rem 2rem;
}

.card-gradient1 {
  background: #E9F4FF;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #F6F6F6, #E9F4FF);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #F6F6F6, #E9F4FF);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.border-radius16 {
  border-radius: 16px;
}

/*----------------------------------------
Projects Nav Tabs
------------------------------------------*/
ul.projects-nav-tab {
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  display: flex;
  background-color: #D0D0D0;
  ;
}

ul.projects-nav-tab li {
  display: inline-flex;
}

ul.projects-nav-tab li a {
  display: block;
  padding: 5px 110px;
  border-radius: 100px;
  color: #000;
  text-align: center;
}

ul.projects-nav-tab li a.active {
  background-color: #0077DC;
  color: #FFF;
  text-decoration: none;
}

ul.projects-nav-tab li:first-child a {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

ul.projects-nav-tab li:last-child a {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

/*==========================================
Chat Page
==========================================*/
#TeamList-sidebar {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 30px 0px 30px;
}

#TeamList-sidebar .girl-image {
  position: relative;
  width: 100%;
  text-align: center;
}

#TeamList-sidebar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#TeamList-sidebar ul li {
  display: block;
  margin-bottom: 15px;
}

#TeamList-sidebar ul li {
  display: block;
}

#TeamList-sidebar ul li a {
  display: block;
  padding: 10px;
  border-radius: 100px;
  text-align: center;
  background-color: #c4e0f7;
  color: #FFF;
}

#TeamList-sidebar ul li a.active {
  background-color: #0077dc;
  color: #FFF;
}

#chatWindow {
  background-color: #ECECEC;
  padding: 30px;
  border-radius: 35px;
  min-height: 600px;
  position: relative;
}

.chatHistory {
  display: block;
  height: 500px;
  overflow-y: scroll;
}

.chatHistory ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}

.chatHistory ul li .sent {
  background: #FFF;
  color: #000;
  padding: 15px;
  border-radius: 16px;
  max-width: 75%;
  position: relative;
}

.chatHistory ul li .sent::before {
  content: "\F5E3";
  font-family: "bootstrap-icons";
  position: absolute;
  bottom: -9px;
  left: -22px;
  transform: rotate(270deg);
  font-size: 2.5rem;
  color: #FFF;
}

.chatHistory ul li .replies {
  background: #FFF;
  color: #000;
  float: right;
  padding: 15px;
  border-radius: 16px;
  max-width: 75%;
  position: relative;
  margin-right: 30px;
}

.chatHistory ul li .replies::before {
  content: "\F5E3";
  font-family: "bootstrap-icons";
  position: absolute;
  bottom: -9px;
  right: -22px;
  transform: rotate(90deg);
  font-size: 2.5rem;
  color: #FFF;
}

.chatHistory ul li .replies p,
.chatHistory ul li .sent p {
  margin-bottom: 0px;
}

.chatHistory ul li .replies .team-name,
.chatHistory ul li .sent .team-name {
  font-size: 13px;
  color: #0077DC;
  display: block;
  margin-bottom: 0px;
}

.chatHistory ul li .replies .recived-time {
  font-size: 12px;
  color: #D9D9D9;
  display: block;
  margin-bottom: 0px;
}

.chatHistory ul li .sent .recived-time {
  font-size: 12px;
  color: #D9D9D9;
  display: block;
  margin-bottom: 0px;
  text-align: right;
}

.chatHistory ul li .sent .recived-time i,
.chatHistory ul li .replies .recived-time i {
  margin-right: 5px;
}

.chatHistory ul li .sent .read-tick,
.chatHistory ul li .replies .read-tick {
  color: #0077DC;
}

.chatHistory ul li .replies .unread-tick,
.chatHistory ul li .sent .unread-tick {
  color: #D9D9D9;
}

.chatsendBox {
  display: block;
  position: relative;
  background: #FFF;
  border-radius: 100px;
  padding: 5px;
  max-height: 60px;
  min-height: 60px;
  margin-top: 20px;
}

.bi-send-fill::before {
  content: "\f6b9";
  transform: rotate(45deg);
}

.chatsendBox ul.floatingbuttons {
  margin: 0;
  padding: 0;
  list-style-type: 0;
}

.chatsendBox ul.floatingbuttons li {
  display: inline-block;
}

.chatsendBox ul.floatingbuttons a {
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 100px;
  text-align: center;
  font-size: 1.5rem;
  line-height: 45px;
  color: #0077DC;
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-left: 5px;
}

.google-meet {
  position: fixed;
  right: 135px;
  bottom: 110px;
}

.Mobile-chatsendBox {
  display: none;
}

/*==========================================
Media Query CSS
==========================================*/

@media (max-width: 768px) {

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    width: 100% !important;
  }

  .navbar a,
  .navbar a:focus {
    justify-content: start;
  }

  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }

  #footer .credits {
    padding-top: 4px;
  }

  .desktop-right {
    text-align: left;
  }

  .navbar a::before {
    right: auto;
    top: 37%;
    left: 22px;
  }

  .page-header .page-title {
    padding-top: 30;
  }

  .Heading-h1 {
    font-size: 54px;
  }

  .kfc-wrapper .kfc-image-items {
    width: 100%;
    height: auto;
  }

  .kfc-wrapper .kfc-image-items .kfc-image {
    width: 100%;
  }

  .bg-cover {
    min-height: 450px;
  }

  .kfc-wrapper .kfc-image-items {
    position: relative;
  }

  .kfc-wrapper .kfc-image-items .kfc-image {
    bottom: 30px;
  }

  ul.Foodmenu-list li {
    width: 100%;
    margin-left: 05px;
    margin-bottom: 10px;
  }

  .EventTitle {
    font-size: 20px;
    font-family: "Potta One", system-ui;
  }

  .mob-mb1 {
    margin-bottom: 1rem;
  }

  #TeamList-sidebar ul li {
    display: inline-block;
  }

  #chatWindow {
    padding: 10px;
  }

  .chatHistory ul li .sent,
  .chatHistory ul li .replies {
    max-width: 100%;
  }

  .TeamList-sidebar {
    padding: 0px;
  }

  .chatsendBox {
    display: none;
  }

  .Mobile-chatsendBox {
    display: block;
    position: fixed;
    background: #FFF;
    border-radius: 0;
    padding: 5px;
    max-height: 110;
    min-height: 110;
    margin-top: 20px;
    bottom: 0;
  }

  .bi-send-fill::before {
    content: "\f6b9";
    transform: rotate(45deg);
  }

  .Mobile-chatsendBox ul.floatingbuttons {
    margin: 0;
    padding: 0;
    list-style-type: 0;
  }

  .Mobile-chatsendBox ul.floatingbuttons li {
    display: inline-block;
  }

  .Mobile-chatsendBox ul.floatingbuttons a {
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 100px;
    text-align: center;
    font-size: 1.5rem;
    line-height: 45px;
    color: #0077DC;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-left: 5px;
  }

  .google-meet {
    position: fixed;
    right: 15px;
    top: 60px;
  }

  .full-rounded {
    border-radius: 100px !important;
  }

  /*Circle Progress bar*/
  .progress {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
  }

  .progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress .progress-left {
    left: 0;
  }

  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: #0077DC;
  }

  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 75px;
    border-bottom-right-radius: 75px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }

  .progress .progress-right {
    right: 0;
  }

  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }

  .progress .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 36px;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 300;
    width: 100%;
  }

  .progress .progress-value div {
    margin-top: 10px;
    font-size: 3rem;
    font-weight: 600;
    color: #0077DC;
  }

  .progress .progress-value span {
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
  }

  /* This for loop creates the 	necessary css animation names 
  Due to the split circle of progress-left and progress right, we must use the animations on each side. 
  */
  .progress[data-percentage="10"] .progress-right .progress-bar {
    animation: loading-1 1.5s linear forwards;
  }

  .progress[data-percentage="10"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="20"] .progress-right .progress-bar {
    animation: loading-2 1.5s linear forwards;
  }

  .progress[data-percentage="20"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="30"] .progress-right .progress-bar {
    animation: loading-3 1.5s linear forwards;
  }

  .progress[data-percentage="30"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="40"] .progress-right .progress-bar {
    animation: loading-4 1.5s linear forwards;
  }

  .progress[data-percentage="40"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="50"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
  }

  .progress[data-percentage="50"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="60"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
  }

  .progress[data-percentage="60"] .progress-left .progress-bar {
    animation: loading-1 1.5s linear forwards 1.5s;
  }

  .progress[data-percentage="70"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
  }

  .progress[data-percentage="70"] .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.5s;
  }

  .progress[data-percentage="80"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
  }

  .progress[data-percentage="80"] .progress-left .progress-bar {
    animation: loading-3 1.5s linear forwards 1.5s;
  }

  .progress[data-percentage="90"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
  }

  .progress[data-percentage="90"] .progress-left .progress-bar {
    animation: loading-4 1.5s linear forwards 1.5s;
  }

  .progress[data-percentage="100"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
  }

  .progress[data-percentage="100"] .progress-left .progress-bar {
    animation: loading-5 1.5s linear forwards 1.5s;
  }

  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(36);
      transform: rotate(36deg);
    }
  }

  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(72);
      transform: rotate(72deg);
    }
  }

  @keyframes loading-3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(108);
      transform: rotate(108deg);
    }
  }

  @keyframes loading-4 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(144);
      transform: rotate(144deg);
    }
  }

  @keyframes loading-5 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(180);
      transform: rotate(180deg);
    }
  }

  .progress {
    margin-bottom: 1em;
  }

  .card-header-blue {
    background-color: #0077DC;
    color: #FFF;
    border-radius: 100px !important;
    text-align: center;
    padding: 15px 15px;
  }

  .progress {
    margin-bottom: 1em;
  }

  .desktop-hide {
    display: none;
  }

  .mobile-hide {
    display: none;
  }

  .desktop-pt200 {
    padding-top: 0px;
  }

  #header .logo img {
    max-height: 150px !important;
  }

  .navbar .dropdown ul {
    left: -100px;
  }

  .notificationDropdown-box {
    right: -90px;
  }
}
@media (min-width: 320px) and (max-width: 767px)
{
.mob-w-100{
  width: 100%;
}
}
